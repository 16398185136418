import React, { useState } from "react";
import TopNavbar from "../../components/topNavbar";
import { Button, Divider, Grid, Paper, Typography, makeStyles } from "@material-ui/core";
import { ArrowForward, ArrowForwardIos, FiberManualRecord, Flare, Instagram, LocalMallOutlined, TheatersOutlined } from "@material-ui/icons";
import { colors } from "../../assets/css/colors";
import minifeelLogo from "../../assets/images/logos/minifeel-logo.avif"
import freakinsLogo from "../../assets/images/logos/freakins-logo.webp"
import myntraLogo from "../../assets/images/logos/myntra-logo.jpeg"
import purpleLogo from "../../assets/images/logos/purple-logo.jpeg"
import urbanicLogo from "../../assets/images/logos/urbanic-logo.jpeg"
import dummyWhite from "../../assets/images/download.png"
import flasIcon from "../../assets/images/lightning.png";
import {useHistory} from "react-router-dom"

import { CustomGrid } from "../../App";
import { GenericBottomDrawer2 } from "../../components/GenericBottomDrawer";

const useStyles = makeStyles((theme) => ({
    paper: {
      padding: theme.spacing(2),
      // textAlign: 'center',
    //   color: "black",
      background: "white",
      position: "relative",
      borderRadius: "5px",
      marginBottom: "4px"
    },
    smallCard: {
        borderRadius: "8px",
        padding: "12px",
        width: "92%"
    },
    smallCard1: {
        borderRadius: "8px",
        padding: "12px",
        background: colors.primaryGradient,
        height: "82%",
        color: "white",
        width: "85%",
        marginLeft: "15px"
    },
    instaLogin: {
        backgroundColor: "#D6EAF8",
        border: `2px solid ${colors.primary}`,
        width: "auto",
        height: "auto",
        padding: "10px",
        borderRadius:    "10px"
    },
    brandCard: {
        height: "180px",
        width: "130px",
        minWidth: "130px",
        border: "1px solid black",
        borderRadius: "8px",
        position: "relative",
        marginRight: "10px",
        marginLeft: "5px",
        padding: "10px",
        cursor: "pointer"
    }
}))

function HomeScreen({ isMobile, userData }) {

    const classes = useStyles()
    const history = useHistory()

    const brands = [
        {"name": "Minifeel", bottomText: "Upto 10% Commission", image: minifeelLogo,
         brandText: "Recommend your favorite products from Minifeel and earn Flat 10% on every successful order!",
         brandWebsite: "https://minifeel.in"
        },
        {"name": "Freakins", bottomText: "Upto 10% Commission", image: freakinsLogo,
         brandText: "Recommend your favorite products from Freakins and earn Flat 10% on every successful order!",
         brandWebsite: "https://freakins.com"
        },
        {"name": "Myntra", bottomText: "Upto 10% Commission", image: myntraLogo,
         brandText: "Recommend your favorite products from Myntra and earn Flat 10% on every successful order!",
         brandWebsite: ""
        },
        {"name": "Purple", bottomText: "Upto 10% Commission", image: purpleLogo,
         brandText: "Recommend your favorite products from Purple and earn Flat 10% on every successful order!",
         brandWebsite: ""
        },
        {"name": "Urbanic", bottomText: "Upto 10% Commission", image: urbanicLogo,
         brandText: "Recommend your favorite products from Urbanic and earn Flat 10% on every successful order!",
         brandWebsite: ""
        },
    ]

    const [openDrawer, setOpenDrawer] = useState(false)
    const [drawerContent, setDrawerContent] = useState({})

    const handleInstaCTA = () => {
        if(userData?.instaHandle === "none"){
            window.open(`https://www.facebook.com/v19.0/dialog/oauth?client_id=1111435320207600&display=page&extras=%7B%22setup%22%3A%7B%22channel%22%3A%22IG_API_ONBOARDING%22%7D%7D&redirect_uri=https%3A%2F%2Fcreator.creatorcheckout.com%2Finstagram-handle&response_type=token&scope=instagram_basic%2Cpages_show_list%2Cbusiness_management%2Cinstagram_manage_comments%2Cinstagram_manage_messages&state=${userData?.phoneNo}-app2`)
        } else {
            history.push("/main/insta-handle")
        }
    }

    return (
        <React.Fragment>
            <TopNavbar isMobile={isMobile} screen={"home"}/>
            <Grid container style={{marginTop: "20%"}}>
                <Grid item xs={12}>
                    <Paper className={classes.paper} elevation={0}>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <Typography variant="h5" style={{fontSize: "16px", fontWeight: "500"}}>
                                    Welcome to your Creator Checkout
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h5" style={{fontSize: "12px", display: "flex", alignItems:"center", opacity: 0.6}}>
                                    Here's an overview of your Creator Checkout activity <Flare style={{color: "orange", fontSize: "12px", marginLeft: "5px"}}/> 
                                </Typography>
                            </Grid>
                            <Grid item xs={12}></Grid>
                            <Grid item xs={12} container spacing={1}>
                                <Grid item xs={6} container spacing={1}>
                                    <Grid item xs={12}>
                                        <Paper elevation={1} className={classes.smallCard}>
                                            <Grid container alignItems="center">
                                                <Grid item style={{display: "flex", alignItems: "center", width: "60%"}}>
                                                    <TheatersOutlined style={{fontSize: "16px", marginRight: "5px"}} />
                                                    <Typography style={{fontSize: "14px", marginTop: "0px"}}>
                                                        Total Posts
                                                    </Typography>
                                                </Grid>
                                                <Grid item style={{marginLeft: "auto", marginTop: "2px"}}>
                                                    0
                                                </Grid>
                                            </Grid>
                                        </Paper>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Paper elevation={1} className={classes.smallCard}>
                                            <Grid container alignItems="center">
                                                <Grid item style={{display: "flex", alignItems: "center", width: "60%"}}>
                                                    <LocalMallOutlined style={{fontSize: "16px", marginRight: "5px"}} />
                                                    <Typography style={{fontSize: "14px", marginTop: "2px"}}>
                                                        Total Collections
                                                    </Typography>
                                                </Grid>
                                                <Grid item style={{marginLeft: "auto", marginTop: "2px"}}>
                                                    0
                                                </Grid>
                                            </Grid>
                                        </Paper>
                                    </Grid>
                                </Grid>

                                <Grid item xs={6}>
                                    <Paper elevation={0} className={classes.smallCard1}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12}>
                                                <Typography style={{fontSize: "11px"}}>
                                                    Net earnings this payout
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}></Grid>
                                            <Grid item xs={12}>
                                                <Typography variant="h4">
                                                ₹ 0
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Paper>
                                </Grid>
                            </Grid>

                            <Grid item xs={12}></Grid>
                            <Grid item xs={12}>
                            <Typography style={{color: "red", fontSize: "14px", marginTop: "5px", marginBottom: "10px", overflowX: "hidden"}}>
                                For Facebook review - Press the Social login button below and use this document for app usage instructions - <a style={{cursor: "pointer", textDecoration: "underline"}} onClick={() => window.open("https://docs.google.com/document/d/1pceDKPkgYIYCDcRDug6cpLkxtgoA79rasaTAwiF2urg/edit?usp=sharing", null)}>
                                https://docs.google.com/document/d/1pceDKPkgYIYCDcRDug6cpLkxtgoA79rasaTAwiF2urg/edit?usp=sharing
                                    </a>
                            </Typography>
                                <div className={classes.instaLogin}>
                                    <Grid container spacing={1} alignItems="center" style={{cursor: "pointer"}} onClick={() => handleInstaCTA()}>
                                        <Grid item xs={11} container spacing={1}>
                                            <Grid item xs={12} style={{display: "flex", alignItems: 'center'}}>
                                                <Instagram style={{fontSize: "16px", marginRight: "5px"}}/>
                                                <Typography style={{fontSize: "14px", marginTop: "1px"}}>
                                                    Social Login
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography style={{fontSize: "14px", opacity: 0.7}}>
                                                    Connect your Instagram for a personalized experience.
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid item xx={1}>
                                            <ArrowForwardIos style={{fontSize: "16px"}} />
                                        </Grid>
                                    </Grid>
                                </div>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>

                <Grid item xs={12}>
                    <Paper className={classes.paper} elevation={0}>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <Typography variant="h5" style={{fontSize: "16px", fontWeight: "500"}}>
                                    Spotlight brands
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h5" style={{fontSize: "12px", display: "flex", alignItems:"center", opacity: 0.6}}>
                                    Discover the most trending brands!
                                </Typography>
                            </Grid>
                            <Grid item xs={12}></Grid>
                            <CustomGrid item xs={12}>
                                <div style={{display: "flex", alignItems: "center"}}>
                                    {brands?.map((val, idx) => (
                                        <Paper className={classes.brandCard} elevation={0} onClick={() => {
                                            setOpenDrawer(true);
                                            setDrawerContent({...val});
                                        }}>
                                            <Grid container justifyContent="center" spacing={1}>
                                                <Grid item>
                                                    <Paper elevation={2} style={{borderRadius: "40px", height: "65px", width: "65px", marginTop: "15%", padding: 5}}>
                                                        <img src={val?.image} style={{height: "65px", width: "65px", borderRadius: "40px", objectFit: "contain"}}/>
                                                    </Paper>
                                                </Grid>
                                                <Grid item xs={12}></Grid>
                                                <Grid item>
                                                    <Typography style={{fontSize: "13px",  fontWeight: "500"}}>
                                                        {val?.name}
                                                    </Typography>
                                                </Grid>
                                                {/* <Grid item xs={12}></Grid> */}
                                                <Grid item>
                                                    <Typography style={{fontSize: "14px", textAlign: "center"}}>
                                                        {val?.bottomText}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Paper>
                                    ))}
                                </div>
                            </CustomGrid>
                        </Grid>
                        <Grid item xs={12}>
                            <GenericBottomDrawer2 open={openDrawer} setOpen={setOpenDrawer} isMobile={isMobile}
                                drawerContent={(
                                    <React.Fragment>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} container spacing={1} justifyContent='center'>
                                                <Grid item>
                                                    <Paper style={{height: "75px", width: "75px", padding: 5, borderRadius: "40px"}}>
                                                        <img src={drawerContent?.image} style={{height: "75px", width: "75px", borderRadius: "50px", objectFit: "contain"}}/>
                                                    </Paper>
                                                </Grid>
                                                <Grid item xs={12}></Grid>
                                                <Grid item>
                                                    <Typography style={{fontSize: "16px", fontWeight: "500"}}>
                                                        {drawerContent?.name}
                                                    </Typography>
                                                </Grid>
                                            </Grid>

                                            <Grid item xs={12} style={{display: "flex"}}>
                                                <FiberManualRecord style={{fontSize: "8px", color: "black", marginRight: "10px", marginTop: '10px'}} />
                                                <Typography style={{fontSize: "14px", color: "grey"}}>
                                                    {drawerContent?.brandText}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Divider style={{color: "black", background: "rgba(0, 0, 0, 0.1)"}} />
                                            </Grid>
                                            <Grid item xs={12}></Grid>
                                            <Grid item xs={12}>
                                                <Button variant="outlined" color="primary" fullWidth style={{borderRadius: "10px", fontWeight: "500", border: "1px solid"}}
                                                    onClick={() => window.open(drawerContent?.brandWebsite)}
                                                >
                                                    Go to brand website
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </React.Fragment>
                                )}
                            />
                        </Grid>
                    </Paper>
                </Grid>

                <Grid item xs={12}>
                    <Paper className={classes.paper} elevation={0}>
                        <Grid container spacing={1} justifyContent="center" style={{height: isMobile ? "auto" : "120px"}}>
                            <Grid item>
                                <div style={{display: "flex", alignItems: "center"}}>
                                    <img src={flasIcon} style={{height: "15px"}}/>
                                    <Typography variant="caption">
                                    Powered by 
                                    </Typography>
                                    <span style={{
                                        // color: "#4D63DD",
                                        background: "linear-gradient(128.8deg, #4D63DD 28.61%, #22A1F5 99.07%)",
                                        WebkitBackgroundClip: 'text',
                                        WebkitTextFillColor: 'transparent', 
                                        fontWeight: "bold",
                                        marginLeft: 3,
                                        fontSize: "13px"
                                        }}>
                                        <i>Paperplane</i></span>
                                </div>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        </React.Fragment>
    )
}


export default HomeScreen;
import React, { useEffect, useState } from "react";
import TopNavbar from "../../components/topNavbar";
import { Button, CircularProgress, Grid, Paper, TextField, Typography, makeStyles } from "@material-ui/core";
import dummyImage from "../../assets/images/download.png"
import GenericBottomDrawer, { GenericBottomDrawer2 } from "../../components/GenericBottomDrawer";
import { colors } from "../../assets/css/colors";
import { ArrowBack } from "@material-ui/icons";
import customAxios from "../../axios";
import {useHistory} from "react-router-dom"
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
    paper: {
      padding: theme.spacing(2),
      // textAlign: 'center',
    //   color: "black",
      background: "white",
      position: "relative",
      borderRadius: "5px",
      marginBottom: "5px"
    },
}))

function InstaHandle({ isMobile, userData }) {

    const classes = useStyles()

    const dummyMediaData = [
        {image: dummyImage},
        {image: dummyImage},
        {image: dummyImage},
        {image: dummyImage},
        {image: dummyImage},
        {image: dummyImage},
        {image: dummyImage},
        {image: dummyImage},
    ]

    const [open, setOpen] = useState(false)
    const [drawerContent, setDrawerContent] = useState(null)

    const handlePostComment = (media) => {
        setDrawerContent(media)
        setOpen(true)
        setLoader(false)
    }

    const [loader, setLoader] = useState(false)

    const handlePostCommentSave = () => {
        setLoader(true)
        if(commentKeyword.length > 0 && commentReply.length > 0) {
            customAxios.post("creator/post-comment-handle", {commentKeyword: commentKeyword, commentReply: commentReply, mediaData: drawerContent}).then((res) => {
                if(res?.data?.success) {
                    setOpen(false)
                    setCommentKeyword('')
                    setCommentReply('')
                    setLoader(false)
                }
            })
        } else {
            setLoader(false)
        }
    }

    const [instaData, setInstaData] = useState(null)
    const [mediaData, setMediaData] = useState([])
    const [commentKeyword, setCommentKeyword] = useState('')
    const [commentReply, setCommentReply] = useState('')

    const [profileFetchError, setProfileFetchError] = useState(false)

    const [allMediaData, setAllMediaData] = useState(null)
    const history = useHistory()

    useEffect(() => {
        customAxios.get("creator/media-data").then((res) => {
            if(res?.data?.success) {
                setInstaData({...res?.data?.data?.profileData})
                setMediaData([...res?.data?.data?.mediaData])
                setAllMediaData({...res?.data?.data})
                setProfileFetchError(false)
            } else {
                setProfileFetchError(true)
            }
        }).catch(err => {
            console.log(err)
            setProfileFetchError(true)
        })
    }, [])

    return (
        <React.Fragment>
            <TopNavbar isMobile={isMobile} backButton={true} navBarText={instaData?.username ?? "username"} userData={userData}/>
            {!profileFetchError ? <Grid container style={{marginTop: "20%"}}>
                <Grid item xs={12}>
                    <Paper className={classes.paper} elevation={0}>
                        <Grid container spacing={1}>
                            <Grid item xs={12} container spacing={2} alignItems="center" style={{display: "flex", alignItems: "center"}}>
                                <Grid item style={{marginRight: "5%"}}>
                                    <Paper elevation={2} style={{borderRadius: "40px", height: "70px", width: "70px", marginTop: "15%"}}>
                                        <img src={instaData?.profile_picture_url ?? dummyImage} style={{height: "70px", width: "70px", borderRadius: "40px", objectFit: "contain"}}/>
                                    </Paper>
                                </Grid>
                                <Grid item style={{textAlign: 'center', marginTop: "4%"}}>
                                    <Typography style={{fontSize: "14px", fontWeight: "bold"}}>
                                        {instaData?.media_count ?? 0}
                                    </Typography>
                                    <Typography style={{fontSize: "14px"}}>
                                        Posts
                                    </Typography>
                                </Grid>
                                <Grid item style={{textAlign: 'center', marginTop: "4%"}}>
                                    <Typography style={{fontSize: "14px", fontWeight: "bold"}}>
                                        {instaData?.followers_count ?? 0}
                                    </Typography>
                                    <Typography style={{fontSize: "14px"}}>
                                        Followers
                                    </Typography>
                                </Grid>
                                <Grid item style={{textAlign: 'center', marginTop: "4%"}}>
                                    <Typography style={{fontSize: "14px", fontWeight: "bold"}}>
                                        {instaData?.follows_count ?? 0}
                                    </Typography>
                                    <Typography style={{fontSize: "14px"}}>
                                        Following
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography style={{textTransform: "uppercase", marginLeft: "1%", fontSize: "15px"}}>
                                    {instaData?.name ?? "Name"}
                                </Typography>
                                <Typography style={{color: "grey", marginLeft: "1%", fontSize: "14px"}}>
                                    Linked Facebook Page - {allMediaData?.pageAccount?.name ?? "Name"}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <Paper className={classes.paper} elevation={0}>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <Typography style={{fontSize: "14px", fontWeight: "500"}}>
                                    Recent Posts & Reels
                                </Typography>
                            </Grid>
                            <Grid item xs={12} container>
                                {mediaData?.map(v => (
                                    <Grid item xs={4}>
                                        <div style={{width: "auto", height: "auto", margin: "5px", borderRadius: "10px", cursor: "pointer"}}
                                            onClick={() => handlePostComment(v)}
                                        >
                                            <img src={v?.thumbnail_url ?? v?.media_url}  style={{width: "99%", borderRadius: "10px"}} />
                                        </div>
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>      

                <Grid item xs={12}>
                    <GenericBottomDrawer2 open={open} setOpen={setOpen} elevation={5} zIndex={5} isMobile={isMobile} drawerContent={
                        <Grid container spacing={2}>
                            <Grid item xs={12} style={{display: "flex", alignItems: "center"}}>
                                <ArrowBack style={{cursor: 'pointer', marginRight: "10px", fontSize: "18px"}} onClick={() => {
                                    setOpen(false)
                                    setCommentKeyword("")
                                    setCommentReply("")
                                    }}/>
                                <Typography style={{fontSize: "14px"}}>
                                    Post Comment Handle
                                </Typography>
                            </Grid>

                            <Grid item xs={12} container spacing={1}>
                                <Grid item xs={12}>
                                    <TextField
                                        value={commentKeyword}
                                        onChange={e => setCommentKeyword(e.target?.value)}
                                        inputProps={{style: {fontSize: "12px"}}}
                                        InputLabelProps={{style: {fontSize: "12px"}}}
                                    size="small" label="Comment Keyword" placeholder="Enter Comment Keyword" variant="standard" />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        value={commentReply}
                                        onChange={e => setCommentReply(e.target?.value)}
                                        inputProps={{style: {fontSize: "12px"}}}
                                        InputLabelProps={{style: {fontSize: "12px"}}}
                                    size="small" label="Comment Response" placeholder="Enter Comment Response" variant="standard" />
                                </Grid>
                            </Grid> 
                            <Grid item xs={12}></Grid>
                            <Grid item xs={12}>
                                <Button variant='contained' fullWidth style={{borderRadius: "20px", background: colors.primaryGradient, color: "white"}} disableElevation onClick={() => {
                                    handlePostCommentSave()
                                }}
                                // startIcon={<LockOutlined />}
                                endIcon={loader ? <CircularProgress style={{color: "white", height: 18, width: 18}} /> : null}
                                >
                                    Submit
                                </Button>
                            </Grid>
                        </Grid>
                    } />
                </Grid>         
            </Grid> : (
                <Grid container style={{marginTop: "20%", paddingTop: "50%"}}>
                    <Grid item>
                        <Typography style={{textAlign: "center"}}>
                            Facebook Login or permission request linking failed. <br/>
                            <Button variant="text" style={{textDecoration: "underline", color: "blue"}} onClick={() => history.push("/main/home")}>
                            Go back to home and try again.
                            </Button>
                        </Typography>
                    </Grid>
                </Grid>
            )}
        </React.Fragment>
    )
}


export default InstaHandle;